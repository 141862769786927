/* Custom styles for Tabs */
.custom-tabs .nav-link {
    font-size: 1.4rem; /* Increase text size */
    padding: 16px 24px; /* Increase padding to make the tabs taller */
    border-radius: 12px; /* Make rounded corners more prominent */
    color: #000; /* Default text color */
    background-color: #f1f1f1; /* Light gray background for inactive tabs */
    padding-bottom: 3px;
}

.custom-tabs .nav-link:hover {
    background-color: #ddd; /* Slightly darker gray on hover */
}

.custom-tabs .nav-link.active {
    background-color: #007bff; /* Blue background for active tab */
    color: white; /* White text for active tab */
    font-weight: bold; /* Bold text for active tab */
}

/* Optional: Customize the tab content area */
.custom-tabs .tab-content {
    margin-top: 20px;
}

.tab-title {
    font-size: 1.2rem; /* Increase font size */
    font-weight: bold;
    color: #000; /* Default text color */
}

.tab-icon {
    font-size: 1.5rem; /* Adjust icon size */
    color: #004fb1; /* Adjust color */
}

.nav-tabs .nav-link.active {
    background-color: #007bff !important; /* Blue background */
    color: white !important; /* White text */
    font-weight: bold;
    border-radius: 8px; /* Add rounded corners */
}

.nav-tabs .nav-link {
    padding: 14px 20px; /* Increase padding for larger tabs */
    font-size: 1.2rem;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; /* Adjust space between checkboxes */
}

.checkbox-container .checkbox-item {
    flex: 1 0 21%; /* Flex-grow 1, flex-shrink 0, and base width of 21% */
    max-width: 250px; /* Optional: limits the maximum width of each item */
    min-width: 200px; /* Optional: limits the minimum width of each item */
}

/* Accordian  */

.custom-accordion {
    font-family: Arial, sans-serif;
}

.accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 4px;
}

.accordion-header {
    padding: 5px;
    /* background-color: #f1f1f1; */
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.accordion-body {
    padding: 15px;
    background-color: #fff;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-column {
    flex: 1;
    margin-right: 10px;
}

.form-column:last-child {
    margin-right: 0;
}

label {
    font-size: 0.9rem;
}

input[type='number'],
input[type='radio'],
input[type='checkbox'] {
    margin-top: 5px;
}

input[type='number'] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input[type='radio'],
input[type='checkbox'] {
    margin-right: 5px;
}

.custom-tabs .nav-link {
    display: flex;
    align-items: center;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.checkbox-item {
    flex: 1 1 45%;
}

.signature-required {
    margin-bottom: 20px;
}

.insurance-type-select {
    width: 100%;
}

.custom-tabs .nav-link {
    display: flex;
    align-items: center;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.checkbox-item {
    flex: 1 1 45%;
}

.signature-required {
    margin-bottom: 20px;
}

.insurance-type-select {
    width: 100%;
}

/* Ensure consistent spacing */
.mb-3 {
    margin-bottom: 1rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

/* Align form fields */
.form-group {
    margin-bottom: 1rem;
}

/* Ensure consistent spacing */
.mb-3 {
    margin-bottom: 1rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

/* Align form fields */
.form-group {
    margin-bottom: 1rem;
}

/* Card styling */
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
    padding: 1.5rem;
}

/* container listing css */

/* Ensure consistent spacing */
.mb-3 {
    margin-bottom: 1rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

/* Center the middle button */
.rounded-circle {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Form styling */
.bg-light {
    background-color: #f8f9fa !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.rounded {
    border-radius: 0.375rem !important;
}

.shipping-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Adds space between elements */
    flex-wrap: wrap; /* Ensures responsiveness */
}

.shipping-form {
    flex: 1; /* Allows forms to take equal space */
    min-width: 300px; /* Prevents forms from shrinking too much */
}

.switch-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-accordion {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden;
}

.accordion-item {
    background-color: #fff;
}

.accordion-header {
    padding: 16px;
    background-color: #f8f9fa;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}

.accordion-header:hover {
    background-color: #e9ecef;
}

.accordion-heading {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: center;
}

.accordion-icon {
    margin-left: 8px;
    display: flex;
    align-items: center;
}

.accordion-body {
    padding: 16px;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.shipping-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Aligns items vertically */
    gap: 20px; /* Adds spacing between elements */
    max-width: 98%; /* Adjust width as needed */
}

.custom-tab-title {
    margin-left: 1%;
    display: flex;
    align-items: center;
}

.custom-tab-title .me-2 {
    margin-right: 0.5rem; /* Optional: adjust space between icon and text */
}

/* Center the icon and text inside the tab title */
.custom-tab-title {
    margin-left: 1%; /* Adds 1% left margin */
    display: flex;
    gap: 0.5rem; /* Adds spacing between the icon and the text */
}

/* Adjust space between the individual tabs */
.custom-tabs .nav-item {
    margin-right: 2rem; /* Adjust space between tabs as needed */
}

/* Optional: Adjust margin between icon and text if needed */
.custom-tab-title .me-2 {
    margin-right: 0; /* Remove additional margin if not needed */
}

/*Header */
.shipping-header {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* padding: 10px 15px; */
    font-family: Arial, sans-serif;
    color: #0a2b62; /* Dark Blue Color */
}

.header-left {
    display: flex;
    /* align-items: center; */
    gap: 5px;
    font-weight: bold;
}

.header-right {
    display: flex;
    /* align-items: center; */
    gap: 5px;
    font-weight: normal;
}

.header-title {
    font-size: 16px;
}

.header-icon {
    font-size: 16px;
}

.heading {
    font-size: 1.25rem;
    border-bottom: var(--border-default);
    /* padding-bottom: 1rem; */
    margin-bottom: 2px;
    padding-bottom: 5px;
}

.text {
    color: '#666';
}

.line {
    width: 98%;
    height: 0;
    border: 1px solid #e3e3e3;
    margin-top: 2px;
    display: inline-block;
}
.line-half {
    width: 46%;
    height: 0;
    border: 1px solid #e3e3e3;
    margin-top: 2px;
    display: inline-block;
}
