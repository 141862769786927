@import '../../variables.scss';
@import '../../mixins.scss';

.addLoad {
    display: flex;
    flex-wrap: wrap;
}

.orderInfoHd {
    font-size: $fs15 - 1;
    font-weight: $font-weight-semibold;
    color: $grey;
    margin-bottom: $space10 - 5;
    display: $block;

    @media (max-width: $xl) {
        font-size: $fs15 - 2;
    }
}

p {
    color: $lightGrey;
    font-size: $fs15 - 1;
    padding: 0;
    margin: 0;

    @media (max-width: $xxl) {
        font-size: $fs15 - 2;
    }
}

input[type='checkbox'] {
    margin-right: 5px;
}

.mapImg {
    border-radius: $border-radius * 3;
    background: $white;
    overflow: hidden;
    margin-top: $space10 + 5;

    img {
        display: $block;
        width: 100%;
    }
}

.addLoadField {
    border-bottom: 1px solid $bdrGrey;
    margin-bottom: $space20;
    padding-bottom: $space10 - 5;
}

.delete {
    height: 44px;
    line-height: 44px;
}
.truckBadge {
    top: -5px !important;
    font-size: 10px;
    position: absolute !important;
}
.mannualUpdateStatus {
    height: 30px;
    width: 10rem;
    line-height: 30px;
    font-size: 14px;
}
.customStatusList {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    padding: 2px 5px 2px 5px;
    font-size: 10px;
    // position: absolute;
    width: 150px;
    right: 0;
    z-index: 10;
    opacity: 1.1;
    background: white;
    border: solid 1px #eaf0f6;
    li {
        &:hover {
            color: #3299cc;
            background-color: #eaf0f6;
        }
    }
}

.loadHistoryTimeline {
    max-height: 26rem;
    overflow-y: auto;
    ul {
        counter-reset: list;
        border-left: 2px solid lightgrey;
        margin: 1em;
        padding: 0;
    }

    ul li {
        list-style: none;
        margin: 0 0 0.5em 0;
        padding: 0;
    }

    ul li:before {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin: 0 0.5em 0 -0.65em;
        border-radius: 50%;
        content: counter(list);
        text-align: center;
        background: lightgrey;
        counter-increment: list;
        font-size: 12px;
        line-height: 31px;
    }
}

.loadHistoryTimelineBody {
    padding: 15px 10px;
    background: hsl(0, 0%, 96.5%);
    margin-left: 15px;
    border-bottom-left-radius: 20px;
}
