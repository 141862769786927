@import '../../variables.scss';
@import '../../mixins.scss';

.container {
    display: $flex;
}

.slideContainer {
    width: calc(325px - (325px * 0.05));
    background: $darkgrey;
    position: sticky;
    top: 0;
    height: 100vh;
}
.sidebarContent {
    height: 100%;
    overflow-y: auto;
}

.mainContainer {
    flex-grow: 1;
    width: 80%;
}

.logoContainer {
    padding: $space10 + 8 $space30 + 5 0;
}

.navContainer {
    padding: $space10 * 6 $space30 + 5 0;
}

.navItem {
    align-items: $alignCenter;
    gap: $space10 + 8;
}
.navParentItem {
    cursor: 'pointer';
    @include d-flex4;
    padding: $space10 $space20;
}
.navItemText {
    color: $white;
    font-size: $fs15 + 1;
    font-weight: $font-weight-medium;
    line-height: normal;
    &:focus,
    &:hover {
        color: $white;
    }
}
.active {
    border-radius: $space10 - 2;
    background: $lightBlack;
    width: 227px;
}
.childNavItemText {
    color: $white;
    font-size: $fs15 + 1;
    font-weight: $font-weight-medium;
    line-height: normal;
    &:focus,
    &:hover {
        color: $white;
    }
}

.subActions {
}
